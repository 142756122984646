<template>
  <div class="exhibitors d-flex flex-column">
    <h1 class="exhibitors__title">{{ $t('exhibitors.title') }}</h1>

    <exhibitor-list
      class="exhibitors__list d-flex flex-column"
      :exhibitors="exhibitors"
      v-if="!exhibitorsAreLoading"
    />
    <loading v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GET_RELATIONS,
  RELATIONS_MODULE,
} from '@/stores/umanize-app/actions/relations/relations.actions';
import Loading from '@/components/loading/Loading.vue';
import {
  APPOINTMENT_MODULE,
  CHECK_USERS_HAVE_APPOINTMENT,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import { APP_EXHIBITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { GET_EXHIBITORS } from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import ExhibitorList from './exhibitors/exhibitor-list/ExhibitorList.vue';

export default {
  name: 'Exhibitors',
  components: {
    ExhibitorList,
    Loading,
  },
  computed: {
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitors', 'exhibitorsAreLoading']),
    eventId() {
      return this.$route.params.eventId;
    },
  },
  methods: {
    ...mapActions(APP_EXHIBITOR_MODULE, [GET_EXHIBITORS]),
    ...mapActions(RELATIONS_MODULE, [GET_RELATIONS]),
    ...mapActions(APPOINTMENT_MODULE, [CHECK_USERS_HAVE_APPOINTMENT]),
  },
  async mounted() {
    const { eventId } = this.$route.params;
    // We need the relationships later on, but not on this view
    await this[GET_RELATIONS]();
    await this[GET_EXHIBITORS]({ eventId });
    await this[CHECK_USERS_HAVE_APPOINTMENT]({
      eventId: this.eventId,
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.exhibitors {
  padding: 2rem;

  &__title {
    margin-bottom: 1rem;
  }

  @include breakpoint(small) {
    padding: 1rem;
  }
}
</style>
