<template>
  <div class="exhibitor" v-if="exhibitor">
    <div :style="{ 'background-image': `url(${kioskBackground})` }" class="background-image"></div>

    <div class="d-flex container flex-column justify-space-between">
      <div class="exhibitor__container container d-flex container justify-space-between">
        <div class="left d-flex gap-2">
          <v-sheet class="exhibitor__infos my-2">
            <exhibitor-presentation :event="event" :exhibitor="exhibitor" />
          </v-sheet>

          <v-sheet v-if="this.exhibitor.users.length > 0" class="exhibitor__infos my-2">
            <exhibitor-presenters :event="event" :exhibitor="exhibitor" />
          </v-sheet>
        </div>

        <div v-if="this.selectedSection" class="exhibitor__selected-section d-flex flex-row">
          <exhibitor-selected-section
            :section="this.selectedSection"
            :backgroundImageUrl="exhibitor.kioskOptions.logo"
            @clear-selected-section="clearSelectedSection"
          />
        </div>

        <div
          v-if="hasTab(0) || hasTab(1) || hasDocuments"
          class="exhibitor__infos exhibitor__tabs--custom my-2"
        >
          <v-tabs
            color="black"
            class="exhibitor__tabs--custom pa-2 ma-2"
            background-color="transparent"
            v-model="tab"
            prev-icon="mdi-arrow-left"
            next-icon="mdi-arrow-right"
            show-arrows
          >
            <v-tab v-if="hasTab(0)" :key="'tab0'" active-class="exhibitor__infos--active">
              {{ this.getTab(0).name }}
            </v-tab>
            <v-tab v-if="hasTab(1)" active-class="exhibitor__infos--active" :key="'tab1'">
              {{ this.getTab(1).name }}
            </v-tab>
            <v-tab v-if="hasDocuments" active-class="exhibitor__infos--active" :key="'documents'">
              {{ $t('lobby.exhibitors.documents.title') }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="exhibitor__infos-items">
            <v-tab-item v-if="hasTab(0)" class="pa-2 ma-2 exhibitor__tab" :key="'tab0'">
              <div v-for="(section, index) in this.getTab(0).sections" :key="`tab0-${index}`">
                <exhibitor-section :section="section" @select-section="setSelectedSection" />
              </div>
            </v-tab-item>

            <v-tab-item v-if="hasTab(1)" class="pa-2 ma-2 exhibitor__tab" :key="'tab1'">
              <div v-for="(section, index) in this.getTab(1).sections" :key="`tab1-${index}`">
                <exhibitor-section :section="section" @select-section="setSelectedSection" />
              </div>
            </v-tab-item>

            <v-tab-item v-if="hasDocuments" class="pa-2 ma-2 exhibitor__tab" :key="'documents'">
              <div>
                <exhibitor-documents :event="event" :exhibitor="exhibitor" />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>

      <div class="exhibitor__bottom d-flex">
        <div class="exhibitor__preview">
          <exhibitor-preview @disconnect="disconnect" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  APP_EXHIBITOR_MODULE,
  CONNECT_TO_EXHIBITOR_VISITOR_SOCKET,
  DISCONNECT_FROM_EXHIBITOR_VISITOR_SOCKET,
} from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { APP_CHATS_MODULE, OPEN_CHAT } from '@/stores/umanize-app/actions/chats/chats.actions';
import {
  APPOINTMENT_MODULE,
  CHECK_USERS_HAVE_APPOINTMENT,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  GET_EXHIBITOR,
  GET_EXHIBITORS,
} from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';

import { CLICK_EXHIBITOR, ENTER_EXHIBITOR, getStatSocket } from '@/socket/stat-namespace';
import ExhibitorDocuments from './exhibitor-documents/ExhibitorDocuments.vue';
import ExhibitorPreview from './exhibitor-preview/ExhibitorPreview.vue';
import ExhibitorPresentation from './exhibitor-presentation/ExhibitorPresentation.vue';
import ExhibitorPresenters from './exhibitor-presenters/ExhibitorPresenters.vue';
import ExhibitorSection from './exhibitor-section/ExhibitorSection.vue';
import ExhibitorSelectedSection from './exhibitor-section/ExhibitorSelectedSection.vue';

export default {
  name: 'Exhibitor',
  components: {
    ExhibitorDocuments,
    ExhibitorPreview,
    ExhibitorPresentation,
    ExhibitorPresenters,
    ExhibitorSection,
    ExhibitorSelectedSection,
  },
  data: () => ({
    tab: null,
    selectedSection: null,
  }),
  computed: {
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitor']),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    presenters() {
      return this.exhibitor.users || [];
    },
    kioskBackground() {
      return this.exhibitor.kioskOptions.image3dUrl;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    eventId() {
      return this.$route.params.eventId;
    },
    hasDocuments() {
      return this.exhibitor.documents?.length || this.exhibitor.kioskOptions.hasMainVideo;
    },
  },
  methods: {
    ...mapActions(APP_EXHIBITOR_MODULE, [
      GET_EXHIBITOR,
      GET_EXHIBITORS,
      CONNECT_TO_EXHIBITOR_VISITOR_SOCKET,
      DISCONNECT_FROM_EXHIBITOR_VISITOR_SOCKET,
    ]),
    ...mapActions(APP_CHATS_MODULE, [OPEN_CHAT]),
    ...mapActions(APPOINTMENT_MODULE, [CHECK_USERS_HAVE_APPOINTMENT]),
    emitClickEvent(type) {
      getStatSocket().emit(CLICK_EXHIBITOR, this.event.id, this.exhibitor.id, type);
    },
    emitStatEvent(event) {
      const { eventId, exhibitorId } = this.$route.params;

      getStatSocket().emit(event, eventId, exhibitorId);
    },
    clearSelectedSection() {
      this.selectedSection = null;
    },
    setSelectedSection(section) {
      this.selectedSection = section;
    },
    hasTab(index) {
      const kioskTabs = this.exhibitor?.kioskTabs;
      if (!kioskTabs?.length) {
        return false;
      }
      return kioskTabs[index].name !== '';
    },
    getTab(index) {
      return this.exhibitor.kioskTabs[index];
    },
    refresh() {
      this.selectedSection = null;
      const { eventId, exhibitorId } = this.$route.params;
      this[CONNECT_TO_EXHIBITOR_VISITOR_SOCKET]({
        eventId,
        exhibitorId,
      });
      this[GET_EXHIBITOR]({
        eventId,
        exhibitorId,
      });
      this[GET_EXHIBITORS]({
        eventId,
      });
      this[CHECK_USERS_HAVE_APPOINTMENT]({
        eventId,
      });
      this.emitStatEvent(ENTER_EXHIBITOR);
    },
    disconnect() {
      this[DISCONNECT_FROM_EXHIBITOR_VISITOR_SOCKET]();
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    '$route.params.exhibitorId'() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  beforeDestroy() {
    this.disconnect();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

.exhibitor {
  &__infos {
    background-color: rgba(0, 0, 0, 0.7) !important;
    color: #ffffff !important;
  }

  &__tabs--custom {
    overflow-y: scroll;
    min-width: 25vw;
    max-width: 25vw;
  }

  &__selected-section {
    max-width: 42vw;
    min-width: 42vw;
    background-color: white;
  }

  &__tab {
    background-color: transparent;
  }

  &__container {
    position: relative;
    max-height: 80vh;

    .left {
      display: flex;

      flex-direction: column;
      justify-content: flex-start;
      gap: 10px;

      height: 80vh;
      max-width: 25vw;
      min-width: 25vw;

      .exhibitor__infos {
        flex: 1 1 0px !important;
        overflow-y: scroll;
        max-height: 35vh;
        min-width: 25vw;
      }
    }
  }

  &__preview {
    min-width: 50%;
  }

  &__bottom {
    justify-content: center;

    .exhibitor__infos {
      width: 50%;
    }
  }

  .theme--light.v-tabs-items.exhibitor__infos-items {
    background-color: transparent !important;
  }

  .exhibitor__tabs--custom .v-tab {
    background-color: white;
    color: white;
    margin-right: 20px;
  }

  .exhibitor__tabs--custom .v-tab--active {
    background-color: black;
    color: white;
    margin-right: 20px;
  }

  ::v-deep .exhibitor__tabs--custom .v-slide-group__content {
    justify-content: center;
  }

  ::v-deep .exhibitor__tabs--custom .v-slide-group__next .v-icon {
    color: white;
  }

  ::v-deep .exhibitor__tabs--custom .v-slide-group__prev .v-icon {
    color: white;
  }

  .background-image {
    background-size: cover;
    background-position: center center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
  }

  .container {
    max-width: 100vw;
  }
}

@media screen and (max-width: 1024px) {
  .exhibitor {
    &__info {
      padding: 0;
    }

    &__selected-section {
      position: absolute;
      z-index: 2;
      overflow-y: unset;
      max-width: 95vw;
      min-width: 95vw;
      min-height: 90vh;
      max-height: 90vh;
    }

    &__tabs--custom {
      min-width: 100%;
      max-width: 100%;
    }

    &__container {
      height: 100vh;
      display: flex;
      flex-direction: column;

      .left {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        height: fit-content;

        .v-sheet {
          flex: 1 1 auto;
        }
      }
    }
  }
}
</style>
